import React from 'react'
import './WhatsappBanner.scss'
import whatsappIcon from '../../assets/images/whatsappBanner/whatsapp.png'
const WhatsappBanner = (link) => {
  return (
    <div
      style={{
        padding: '10px 10px 0px 10px',
      }}
    >
      <div class="cardContainer">
        <div class="newBadge "> New</div>{' '}
        <span class="text">
          Easily Deposit and Withdraw from WhatsApp. <br></br>We have a
          dedicated agent assigned for you.
        </span>
        <button onClick={() => window.open(link.link)} class="Button">
          <div class="shimmer"></div>
          <svg
            width="40"
            height="40"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4987 26.5865L11.8414 21.6851C10.8014 19.8807 10.3859 17.7836 10.6591 15.719C10.9323 13.6543 11.879 11.7375 13.3525 10.2657C14.826 8.79387 16.7439 7.8493 18.8089 7.57843C20.8738 7.30756 22.9705 7.72553 24.7737 8.76752C26.577 9.80952 27.986 11.4173 28.7825 13.3417C29.579 15.266 29.7183 17.3993 29.1789 19.4109C28.6396 21.4225 27.4516 23.1999 25.7992 24.4676C24.1468 25.7354 22.1224 26.4225 20.0397 26.4225H20.0358C18.456 26.4226 16.9014 26.0266 15.5142 25.2709L10.4987 26.5865Z"
              fill="white"
            />
            <g filter="url(#filter0_d_7901_9982)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.4984 26.8379C10.4602 26.8378 10.4225 26.829 10.3883 26.812C10.3541 26.7951 10.3242 26.7705 10.301 26.7402C10.2778 26.7098 10.2619 26.6746 10.2545 26.6371C10.2471 26.5996 10.2484 26.561 10.2583 26.5241L11.5744 21.7216C10.4298 19.685 10.0577 17.3041 10.5266 15.0154C10.9954 12.7268 12.2736 10.6839 14.1268 9.26141C15.98 7.83895 18.2839 7.1323 20.6159 7.27106C22.948 7.40982 25.1518 8.3847 26.8233 10.0169C28.4947 11.6491 29.5216 13.8291 29.7158 16.1572C29.9099 18.4853 29.2581 20.8054 27.8801 22.6918C26.5021 24.5783 24.4901 25.9047 22.2132 26.4277C19.9364 26.9508 17.5473 26.6354 15.4841 25.5395L10.5627 26.8297C10.5418 26.8355 10.5201 26.8382 10.4984 26.8379Z"
                fill="white"
              />
            </g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.603 11.3987C24.2362 10.0322 22.4141 9.21683 20.4844 9.10815C18.5548 8.99948 16.6527 9.60512 15.1411 10.8095C13.6295 12.0139 12.6144 13.7327 12.2893 15.6379C11.9642 17.543 12.3521 19.5012 13.3788 21.1387L13.5663 21.436L12.7715 24.3388L15.7481 23.5582L16.0356 23.7284C17.2469 24.446 18.6288 24.8245 20.0367 24.8244H20.0398C21.5954 24.8246 23.1161 24.3636 24.4096 23.4995C25.7032 22.6355 26.7114 21.4073 27.3069 19.9702C27.9024 18.5331 28.0583 16.9517 27.755 15.426C27.4517 13.9003 26.7028 12.4988 25.603 11.3987Z"
              fill="#40C351"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.6747 13.0041C17.4978 12.6118 17.3115 12.6024 17.1424 12.5953C17.0044 12.5895 16.8471 12.5898 16.6894 12.5898C16.5696 12.5931 16.4517 12.621 16.3432 12.672C16.2347 12.723 16.138 12.7958 16.059 12.886C15.7916 13.1391 15.58 13.4451 15.4375 13.7846C15.295 14.124 15.2248 14.4894 15.2313 14.8575C15.2313 16.0206 16.0786 17.1448 16.1967 17.3025C16.3148 17.4602 17.8324 19.9233 20.237 20.8722C22.2341 21.6594 22.6404 21.5029 23.0743 21.4637C23.5081 21.4245 24.4731 20.8922 24.67 20.3399C24.8245 19.9867 24.8725 19.5961 24.8081 19.216C24.7489 19.1172 24.5912 19.0591 24.355 18.9414C24.1189 18.8237 22.9562 18.251 22.7393 18.1726C22.5224 18.0941 22.3651 18.0549 22.207 18.2903C22.0489 18.5256 21.5947 19.0567 21.4566 19.2137C21.3185 19.3706 21.182 19.3917 20.9443 19.2729C20.2473 18.995 19.6039 18.598 19.043 18.0996C18.5263 17.6223 18.0832 17.0711 17.7281 16.4639C17.59 16.2285 17.7132 16.0995 17.8316 15.9814C17.938 15.8751 18.067 15.7068 18.1847 15.5671C18.2813 15.4482 18.3606 15.3161 18.42 15.1749C18.4515 15.1096 18.4661 15.0376 18.4626 14.9652C18.4591 14.8929 18.4376 14.8226 18.4 14.7606C18.3439 14.6406 17.885 13.4713 17.6747 13.0041Z"
              fill="white"
            />
          </svg>
          Quick Deposit and Withdraw
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_7901_9971)">
              <path d="M10 6L9 7L14 12L9 17L10 18L16 12L10 6Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_7901_9971">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default WhatsappBanner
