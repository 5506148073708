// import './pop-up.scss'
import React, { useState, useEffect, useRef } from 'react'
import { toastr } from 'react-redux-toastr'
import {
  default as PhoneInput,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input'
import { CONFIG as social, CONFIG } from '../../../config/api.config'
import { useHistory } from 'react-router'
import {
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import { connectLoginPopUp } from '../loginPopUp.connect'
import SignUpStep1 from '../SignUpTab/images/step1.svg'
import SignUpStep2 from '../SignUpTab/images/step2.svg'
import allSet from './images/allSet.png'
import rulesAccepted from './images/rulesAccepted.svg'
import eye from '../../../assets/images/eye.svg'
import eyeOff from '../../../assets/images/eyeOff.svg'
import closeLogin from '../../../assets/images/closeCross2.svg'
import arrow from '../../../assets/images/arrow.svg'
import OtpInput from 'react-otp-input'

const ForgotPasswordTab = ({
  actions,
  recaptchaResponse,
  onDidDismiss,
  changeTab,
  width,
}) => {
  const history = useHistory()

  const [mobile, setMobile] = useState(false)
  const [countryCode, setCountryCode] = useState(false)

  const [isValid, setIsValid] = useState(false)
  const [message, setMessage] = useState('')
  const [step, setStep] = useState(5)
  const [isSending, setIsSending] = useState(false)
  const [memberCode, setMemberCode] = useState()

  const [hideFirstPass, setHideFirstPass] = useState(false)
  const [hideSecondPass, setHideSecondPass] = useState(false)

  const [mobileNumCounter, setMobileNumCounter] = useState(0)
  const [fgPassTab, setFgPassTab] = useState('wa')
  const refRecaptcha = useRef(null)

  const getBalance = (memberCode) => {
    actions
      .requestBalance(memberCode)
      .then((res) => {
        if (res.data.result.availBalance < 100) {
          // history.push("/deposit")
        }
      })
      .catch((e) => console.log('balance error = ', e))
  }

  const login = async () => {
    try {
      if (!mobile || !password) return
      setIsSending(true)
      const recaptchaResponseExecute =
        await recaptchaResponse.current.executeAsync()

      let newUserName = ''
      newUserName = `91_${mobile}`
      await actions
        .requestAuth({
          username: newUserName,
          password,
          recaptchaResponse: recaptchaResponseExecute,
          answer: '',
          token: '',
        })
        .then((res) => {
          console.log('res', res)
          getBalance(res.data.result.memberCode)
          onDidDismiss()
          setIsSending(false)
        })
    } catch (e) {
      setIsSending(false)
      toastr.error('', `${e}`)
      if (refRecaptcha.current) refRecaptcha.current.reset()
    }
  }

  const mobileValidation = async (e) => {
    setIsSending(true)
    console.log(isSending)
    if (mobile.length !== 10) {
      setMessage('This field is required!')
      setIsSending(false)
      return
    } else if (isValid) {
      // actions.requestRecaptcha()

      setMessage('')
      const recaptchaResponseExecute =
        await recaptchaResponse.current.executeAsync()
      console.log('recaptchaResponse', recaptchaResponseExecute)
      const data = {
        countryCode: `+${countryCode}`,
        mobileNumber: `${mobile}`,
        recaptchaResponse: `${recaptchaResponseExecute}`,
      }

      await actions
        .postNumberForgotPassword(data)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            setStep(2)
            setIsSending(false)
          }
        })
        .catch((e) => toastr.error('', `${e}`))

      console.log('SENDING MOB')
    }
  }

  const initialMinute = 0,
    initialSeconds = 59
  const [minutes, setMinutes] = useState(initialMinute)
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    if (step === 2) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval)
          } else {
            setMinutes(minutes - 1)
            setSeconds(59)
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval)
      }
    }
  })

  const validateMobile = (value) => {
    setMobileNumCounter(value.length)
    setIsSending(false)
    if (!value) {
      setMessage('This field is required!')
      return
    } else if (value.length !== 10) {
      setMessage('Phone number must be 10 digits')
      setIsValid(false)
    } else if (isPossiblePhoneNumber(`+91${value}`)) {
      setIsValid(true)
      setMobile(value)
      const phoneNumber = parsePhoneNumber(`+91${value}`)
      if (phoneNumber) {
        const code = phoneNumber.countryCallingCode
        setCountryCode(code)
        setMessage('')
      }

      setMessage('')
    }
    console.log(isPossiblePhoneNumber(`+91${value}`))
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      // submit()
    }
  }

  const [otpMessage, setOtpMessage] = useState('')
  const [isOtpValid, setIsOtpValid] = useState(false)
  const [password, setPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [isConfirmValid, setConfirmIsValid] = useState(false)

  const [passwordMessage, setPasswordMessage] = useState('')
  const [confirmMessage, setConfirmMessage] = useState('')
  const [hasErrored, setHasErrored] = useState('')

  const [isRulesAccepted, setIsRulesAccepted] = useState('')

  const ref_input = useRef()
  const [otp, setOtp] = useState()

  const isDisabledButton = () => {
    if (
      isSending ||
      otpMessage ||
      confirmMessage ||
      passwordMessage ||
      !isPasswordValid ||
      !isConfirmValid ||
      !isPasswordValid
    ) {
      return true
    } else {
      return false
    }
  }

  const validatePassword = (password) => {
    // event.preventDefault();
    setIsSending(false)
    const regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
    const regexTesting = regex.test(password)

    if (password.length < 8 || password.length > 40) {
      setIsPasswordValid(false)
      setPasswordMessage('The password must be between 8 and 40 characters')
    } else if (!regexTesting) {
      console.log('regex tested')
      setIsPasswordValid(false)
      setPasswordMessage(
        'This field can contain letters(a-z), numbers(0-9) or special symbols'
      )
    } else {
      setIsPasswordValid(true)
      setPasswordMessage('')
      setConfirmIsValid(true)
      setConfirmMessage(true)
      setPassword(password)
    }
  }

  const validatePasswordConfirm = (confirmPassword) => {
    setIsSending(false)
    // event.preventDefault();

    // const confirmPassword = event.target.value;

    if (confirmPassword.length < 8 || confirmPassword.length > 40) {
      setConfirmIsValid(false)
      setConfirmMessage('The password must be between 8 and 40 characters')
    } else {
      setConfirmIsValid(true)
      setConfirmMessage('')
      setPasswordMessage('')
      setIsPasswordValid(true)
      setConfirmPassword(confirmPassword)
    }
  }

  const validateOtp = (otp) => {
    console.log('startOTPVALID', otp)
    setIsSending(false)
    // const otp = event.target.value;

    if (otp.length != 4) {
      setIsOtpValid(false)
      setOtpMessage('OTP is incorrect!')
    } else {
      ref_input.current.focus()
      setIsOtpValid(true)
      setOtpMessage('')
      setOtp(otp)
    }
    console.log('otpMessage', otpMessage)
  }

  const resendOTP = async () => {
    const dataOTP = {
      countryCode: `+${countryCode}`,
      mobileNumber: `${mobile}`,
    }

    await actions
      .resendOTP(dataOTP)
      .then((res) => {
        if (res.status === 200) {
          toastr.success('', `${res.data.result}`)
          setSeconds(59)
        }
      })
      .catch((e) => toastr.error('', `${e}`))
  }

  const next = async (e) => {
    setIsSending(true)
    if (!otp) {
      setOtpMessage('This field is required!')
      setIsValid(false)
      setHasErrored(true)
      console.log('OTP Empty')
      setIsSending(false)
    } else if (otp.length != 4) {
      setIsValid(false)
      setHasErrored(true)
      setOtpMessage('OTP you entered is invalid')
      console.log('OTP invalid')
      setIsSending(false)
    } else if (!password || !confirmPassword) {
      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage('This field is required!')
      setConfirmIsValid(false)
      setConfirmMessage('This field is required!')
      console.log('pass do not match')
      setIsSending(false)
    } else if (password !== confirmPassword) {
      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage('Passwords do not match')
      setConfirmIsValid(false)
      setConfirmMessage('Passwords do not match')
      console.log('pass do not match')
      setIsSending(false)
    } else if (
      isOtpValid &&
      isValid &&
      isConfirmValid &&
      otp.length === 4 &&
      password === confirmPassword
    ) {
      setIsOtpValid(true)
      setMessage('')
      setHasErrored(false)
      setConfirmMessage('')
      setConfirmIsValid(true)
      setIsValid(true)
      console.log('VALIDATION PASSED')
      const data = {
        countryCode: `+${countryCode}`,
        mobileNumber: `${mobile}`,
        OTP: `${otp}`,
        password: `${password}`,
        confirmPassword: `${confirmPassword}`,
      }
      await actions
        .verifyForgotPasswordOTP(data)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            setMemberCode(res?.data?.result?.memberCode)
            console.log(res?.data?.result?.memberCode)
            console.log(memberCode)
            setStep(3)
            setIsSending(false)
            // console.log('SUCCESS')
          }
        })
        .catch((e) => toastr.error('', `${e}`))

      // setPreVal(true)
    }
  }

  const [focus, setFocus] = useState(false)

  const onfocusInput = () => {
    setFocus(true)
  }
  const onBlurInput = () => {
    setFocus(false)
  }

  const [otpSent, setOtpSent] = useState(false)
  const [isSendingNew, setIsSendingNew] = useState(false)

  const mobileValidationNew = async (e) => {
    setIsSendingNew(true)
    console.log(isSending)
    if (mobile.length !== 10) {
      setMessage('This field is required!')
      setIsSendingNew(false)
      return
    } else if (isValid) {
      // actions.requestRecaptcha()

      setMessage('')
      const recaptchaResponseExecute =
        await recaptchaResponse.current.executeAsync()
      console.log('recaptchaResponse', recaptchaResponseExecute)
      const data = {
        countryCode: `+${countryCode}`,
        mobileNumber: `${mobile}`,
        recaptchaResponse: `${recaptchaResponseExecute}`,
      }

      await actions
        .postNumberForgotPassword(data)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            toastr.success('', `${res?.data?.result}`)
            setOtpSent(true)
          }
        })
        .catch((e) => {
          setIsSendingNew(false)
          toastr.error('', `${e}`)
        })

      console.log('SENDING MOB')
    }
  }

  // const mobileValidationNew = async (e) => {

  //   setIsSendingNew(true)

  //   if (mobile.length !== 10) {
  //     setMessage('This field is required!')
  //     setIsSendingNew(false)
  //     return
  //   } else if (isValid) {
  //     // actions.requestRecaptcha()

  //     setMessage('')
  //     const recaptchaResponseExecute =
  //       await recaptchaResponse.current.executeAsync()
  //     console.log('recaptchaResponse', recaptchaResponseExecute)
  //     const data = {
  //       countryCode: `+${countryCode}`,
  //       mobileNumber: `${mobile}`,
  //       recaptchaResponse: `${recaptchaResponseExecute}`,
  //     }

  //     await actions
  //       .postNumberSignUp(data)
  //       .then((res) => {
  //         console.log(res)
  //         if (res.status === 200) {
  //           // console.log('res', res)
  //           toastr.success('', `${res?.data?.result}`)
  //           setOtpSent(true)
  //         }
  //       })
  //       .catch((e) => {
  //         setIsSendingNew(false)
  //         toastr.error('', `${e}`)
  //       })

  //     console.log('SENDING MOB')
  //   }
  // }

  const initialMinuteNew = 0,
    initialSecondsNew = 59
  const [minutesNew, setMinutesNew] = useState(initialMinuteNew)
  const [secondsNew, setSecondsNew] = useState(initialSecondsNew)

  const setTimer = () => {
    let myIntervalNew = setInterval(() => {
      console.log('minusSet')
      if (secondsNew > 0) {
        setSecondsNew(secondsNew - 1)
        console.log('minus1')
      }
      if (secondsNew === 0) {
        if (minutesNew === 0) {
          clearInterval(myIntervalNew)
        } else {
          setMinutesNew(minutesNew - 1)
          setSecondsNew(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myIntervalNew)
    }
  }

  useEffect(() => {
    if (otpSent) {
      let myIntervalNew = setInterval(() => {
        console.log('minusSet')
        if (secondsNew > 0) {
          setSecondsNew(secondsNew - 1)
          console.log('minus1')
        }
        if (secondsNew === 0) {
          if (minutesNew === 0) {
            clearInterval(myIntervalNew)
          } else {
            setMinutesNew(minutesNew - 1)
            setSecondsNew(59)
          }
        }
      }, 1000)
      return () => {
        clearInterval(myIntervalNew)
      }
    }
  })

  const nextNewWa = async (e) => {
    // setIsSending(true)
    
    if (!otp) {
      setOtpMessage('This field is required!')
      setIsValid(false)
      setHasErrored(true)
      console.log('OTP Empty')
      setIsSending(false)
    } else if (otp.length < 4) {
      setIsValid(false)
      setHasErrored(true)
      setOtpMessage('OTP you entered is invalid')
      console.log('OTP invalid')
      setIsSending(false)
    } else if (!password || !confirmPassword) {
      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage('This field is required!')
      setConfirmIsValid(false)
      setConfirmMessage('This field is required!')
      console.log('pass do not match')
      setIsSending(false)
    } else if (password !== confirmPassword) {
      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage('Passwords do not match')
      setConfirmIsValid(false)
      setConfirmMessage('Passwords do not match')
      console.log('pass do not match')
      setIsSending(false)
    } else if (
      isConfirmValid &&
      otp.length >= 4 &&
      password === confirmPassword
    ) {
      setIsOtpValid(true)
      setMessage('')
      setHasErrored(false)
      setConfirmMessage('')
      setConfirmIsValid(true)
      setIsValid(true)
      console.log('VALIDATION PASSED')
      const data = {
        // countryCode: `+${countryCode}`,
        // mobileNumber: `${mobile}`,
        OTP: `${otp}`,
        password: `${password}`,
        confirmPassword: `${confirmPassword}`,
      }
      await actions
        .verifyForgotPasswordOTP(data)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            //           setToken(res.headers.authorization)
            //           setIsSignUpDone(true)
            //           setIsSending(false)
            //           login()
            setMemberCode(res?.data?.result?.memberCode)
            console.log(res?.data?.result?.memberCode)
            console.log(memberCode)
            login()
            setIsSending(false)
          }
        })
        .catch((e) => toastr.error('', `${e}`))

      // setPreVal(true)
    }
  }
  
  const nextNew = async (e) => {
    setIsSending(true)
    if (!otp) {
      setOtpMessage('This field is required!')
      setIsValid(false)
      setHasErrored(true)
      console.log('OTP Empty')
      setIsSending(false)
    } else if (otp.length != 4) {
      setIsValid(false)
      setHasErrored(true)
      setOtpMessage('OTP you entered is invalid')
      console.log('OTP invalid')
      setIsSending(false)
    } else if (!password || !confirmPassword) {
      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage('This field is required!')
      setConfirmIsValid(false)
      setConfirmMessage('This field is required!')
      console.log('pass do not match')
      setIsSending(false)
    } else if (password !== confirmPassword) {
      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage('Passwords do not match')
      setConfirmIsValid(false)
      setConfirmMessage('Passwords do not match')
      console.log('pass do not match')
      setIsSending(false)
    } else if (
      isOtpValid &&
      isValid &&
      isConfirmValid &&
      otp.length === 4 &&
      password === confirmPassword
    ) {
      setIsOtpValid(true)
      setMessage('')
      setHasErrored(false)
      setConfirmMessage('')
      setConfirmIsValid(true)
      setIsValid(true)
      console.log('VALIDATION PASSED')
      const data = {
        countryCode: `+${countryCode}`,
        mobileNumber: `${mobile}`,
        OTP: `${otp}`,
        password: `${password}`,
        confirmPassword: `${confirmPassword}`,
      }
      await actions
        .verifyForgotPasswordOTP(data)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            //           setToken(res.headers.authorization)
            //           setIsSignUpDone(true)
            //           setIsSending(false)
            //           login()
            setMemberCode(res?.data?.result?.memberCode)
            console.log(res?.data?.result?.memberCode)
            console.log(memberCode)
            login()
            setIsSending(false)
          }
        })
        .catch((e) => toastr.error('', `${e}`))

      // setPreVal(true)
    }
  }
  

  // const nextNew = async (e) => {
  //   setIsSending(true)
  //   if (!otp) {
  //     setOtpMessage('This field is required!')
  //     setIsValid(false)
  //     setHasErrored(true)
  //     console.log('OTP Empty')
  //     setIsSending(false)
  //   } else if (otp.length != 4) {
  //     setIsValid(false)
  //     setHasErrored(true)
  //     setOtpMessage('OTP you entered is invalid')
  //     console.log('OTP invalid')
  //     setIsSending(false)
  //   } else if (!password || !confirmPassword) {
  //     setIsValid(false)
  //     setHasErrored(true)
  //     setPasswordMessage('This field is required!')
  //     setConfirmIsValid(false)
  //     setConfirmMessage('This field is required!')
  //     console.log('pass do not match')
  //     setIsSending(false)
  //   } else if (password !== confirmPassword) {
  //     setIsValid(false)
  //     setHasErrored(true)
  //     setPasswordMessage('Passwords do not match')
  //     setConfirmIsValid(false)
  //     setConfirmMessage('Passwords do not match')
  //     console.log('pass do not match')
  //     setIsSending(false)
  //   } else if (
  //     isOtpValid &&
  //     isValid &&
  //     isConfirmValid &&
  //     otp.length === 4 &&
  //     password === confirmPassword
  //   ) {
  //     setIsOtpValid(true)
  //     setMessage('')
  //     setHasErrored(false)
  //     setConfirmMessage('')
  //     setConfirmIsValid(true)
  //     setIsValid(true)
  //     console.log('VALIDATION PASSED')
  //     const data = {
  //       countryCode: `+${countryCode}`,
  //       mobileNumber: `${mobile}`,
  //       OTP: `${otp}`,
  //       password: `${password}`,
  //       confirmPassword: `${confirmPassword}`,
  //     }
  //     await actions
  //       .verifyForgotPasswordOTP(data)
  //       .then((res) => {
  //         console.log(res)
  //         if (res.status === 200) {
  //           setMemberCode(res?.data?.result?.memberCode);
  //           console.log(res?.data?.result?.memberCode);
  //           console.log(memberCode);
  //           setStep(3)
  //           setIsSending(false)
  //           // console.log('SUCCESS')
  //         }
  //       })
  //       .catch((e) => toastr.error('', `${e}`))

  //     // setPreVal(true)
  //   }
  // }

  // const nextNew2 = async (e) => {
  //   setIsSending(true)
  //   if (!otp) {
  //     setOtpMessage('This field is required!')
  //     setIsValid(false)
  //     setHasErrored(true)
  //     console.log('OTP Empty')
  //     setIsSending(false)
  //   } else if (otp.length != 4) {
  //     setIsValid(false)
  //     setHasErrored(true)
  //     setOtpMessage('OTP you entered is invalid')
  //     console.log('OTP invalid')
  //     setIsSending(false)
  //   } else if (!password || !confirmPassword) {
  //     setIsValid(false)
  //     setHasErrored(true)
  //     setPasswordMessage('This field is required!')
  //     setConfirmIsValid(false)
  //     setConfirmMessage('This field is required!')
  //     console.log('pass do not match')
  //     setIsSending(false)
  //   } else if (password !== confirmPassword) {
  //     setIsValid(false)
  //     setHasErrored(true)
  //     setPasswordMessage('Passwords do not match')
  //     setConfirmIsValid(false)
  //     setConfirmMessage('Passwords do not match')
  //     console.log('pass do not match')
  //     setIsSending(false)
  //   } else if (
  //     isOtpValid &&
  //     isValid &&
  //     isConfirmValid &&
  //     otp.length === 4 &&
  //     password === confirmPassword
  //   ) {
  //     setIsOtpValid(true)
  //     setMessage('')
  //     setHasErrored(false)
  //     setConfirmMessage('')
  //     setConfirmIsValid(true)
  //     setIsValid(true)
  //     console.log('VALIDATION PASSED')
  //     const data = {
  //       countryCode: `+${countryCode}`,
  //       mobileNumber: `${mobile}`,
  //       OTP: `${otp}`,
  //       password: `${password}`,
  //       confirmPassword: `${confirmPassword}`,
  //     }
  //     await actions
  //       .verifyOTP(data)
  //       .then((res) => {
  //         console.log(res)
  //         if (res.status === 200) {
  //           setMemberCode(res?.data?.result?.memberCode);
  //           setToken(res.headers.authorization)
  //           setIsSignUpDone(true)
  //           setIsSending(false)
  //           login()
  //         }
  //       })
  //       .catch((e) => {
  //         setIsSending(false)
  //         toastr.error('', `${e}`)
  //       })

  //     // setPreVal(true)
  //   }
  // }

  return (
    <>
      {/* 1 STEP --- MOBILE */}

      {step === 1 && (
        <>
          {width > 1024 && (
            <div className="signUpStep">
              <img src={SignUpStep1} />
            </div>
          )}
          {width < 1024 && (
            <div
              className="pop-up-content_title"
              onClick={() => onDidDismiss(false)}
            >
              <span className="pop-up-content_title-step"> Step 1 of 2 </span>
              <img src={closeLogin} />
            </div>
          )}
          <div
            className={`loginPopUpContainer signUpStep-one ${
              focus ? 'focus' : ''
            }`}
          >
            <div className="forgotTitle">Forgot password</div>
            <IonList class="login-content__list">
              <div className="b2c_login">
                <IonItem>
                  <div className="placeholder">+91</div>
                  <IonInput
                    className="signUpInput"
                    formcontrolname="username"
                    placeholder="10 Digit Phone Number"
                    onFocus={onfocusInput}
                    onIonBlur={onBlurInput}
                    name="username"
                    type="tel"
                    autoComplete="username"
                    onIonChange={(e) => validateMobile(e.target.value)}
                  />
                </IonItem>
                <div className="signUpMessageError">{message}</div>
              </div>
            </IonList>
          </div>

          <div className="pop-up-content__buttons">
            <button
              onFocus={width < 1024 && !isSending && mobileValidation}
              onClick={!isSending && mobileValidation}
              className="pushable next-button"
              disabled={isSending || message}
            >
              <span className="shadow"></span>
              <span className="edge "></span>
              {width > 1024 && (
                <span className="front refreshBtn login">Next</span>
              )}
              {width < 1024 && (
                <div className="front refreshBtn">
                  <span>Next</span>
                  <div className="next-button-icon"></div>
                </div>
              )}
            </button>
          </div>
        </>
      )}

      {/* 2 STEP --- OTP */}

      {step === 2 && (
        <>
          {width > 1024 && (
            <div className="signUpStep">
              <img
                src={SignUpStep2}
                style={{ marginTop: '24px', marginBottom: '44px' }}
              />
            </div>
          )}
          {width < 1024 && (
            <div
              className="pop-up-content_title"
              onClick={() => onDidDismiss(false)}
            >
              <span className="pop-up-content_title-step"> Step 2 of 2 </span>
              <img src={closeLogin} />
            </div>
          )}
          <div className="loginPopUpContainer forgot-step ">
            <div className="OTPText">Enter OTP</div>
            <div className="OTPSubText">
              Please enter four digit OTP which has <br /> been sent to your
              mobile number
            </div>

            <IonList class="login-content__list step-two">
              <div className="b2c_login forgot-pass">
                <div className="login-content__list__input-wrapper">
                  <IonItem>
                    <IonInput
                      className="signUpInput"
                      onFocus={onfocusInput}
                      onIonBlur={onBlurInput}
                      formcontrolname="otp"
                      placeholder="OTP"
                      name="otp"
                      type="number"
                      pattern="\d*"
                      autoComplete="off"
                      maxLength={4}
                      onIonChange={(e) => validateOtp(e.target.value)}
                      // onIonChange={(e) => validateMobile(e.target.value)}
                    />
                  </IonItem>
                  <div
                    className={`signUpMessageError ${
                      otpMessage ? 'otpMessage' : ''
                    }`}
                  >
                    {otpMessage}
                  </div>
                  <div className="resendOTP">
                    <div
                      style={
                        seconds === 0
                          ? { textDecoration: 'underline', cursor: 'pointer' }
                          : null
                      }
                      onClick={() => (seconds === 0 ? setSeconds(59) : null)}
                    >
                      <span
                        className="resendOTP"
                        onClick={seconds === 0 ? resendOTP : null}
                      >
                        Resend OTP
                      </span>
                      {minutes === 0 && seconds === 0 ? null : (
                        <span>
                          {' '}
                          in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="login-content__list__input-wrapper">
                  <IonItem>
                    <IonInput
                      onFocus={onfocusInput}
                      onIonBlur={onBlurInput}
                      required
                      ref={ref_input}
                      formcontrolname="122"
                      placeholder="New Password"
                      name="122"
                      className={
                        hideFirstPass
                          ? 'signUpInput signup-password'
                          : 'signUpInput passwordDots'
                      }
                      // type={show ? "text" : "password"}
                      // value={password}
                      autoComplete="off"
                      onKeyDown={onKeyDown}
                      onIonChange={(e) => validatePassword(e.target.value)}
                      // onIonChange={changePass}
                    />
                    <img
                      className="icons"
                      src={hideFirstPass ? eyeOff : eye}
                      onClick={() => setHideFirstPass(!hideFirstPass)}
                    />
                  </IonItem>
                  <div className="signUpMessageError">{passwordMessage}</div>
                </div>
                <div className="login-content__list__input-wrapper">
                  <IonItem className="confirmPasswordInput">
                    <IonInput
                      onFocus={onfocusInput}
                      onIonBlur={onBlurInput}
                      formcontrolname="122"
                      placeholder="Confirm New Password"
                      name="122"
                      className={
                        hideSecondPass
                          ? 'signUpInput'
                          : 'signUpInput passwordDots'
                      }
                      // type={show ? "text" : "password"}
                      // value={password}
                      autoComplete="off"
                      onKeyDown={onKeyDown}
                      onIonChange={(e) =>
                        validatePasswordConfirm(e.target.value)
                      }
                      // onIonChange={changePass}
                    />
                    <img
                      className="icons"
                      src={hideSecondPass ? eyeOff : eye}
                      onClick={() => setHideSecondPass(!hideSecondPass)}
                    />
                  </IonItem>
                  <div className="signUpMessageError">{confirmMessage}</div>
                </div>
              </div>
            </IonList>
            <div>{message}</div>
          </div>

          <div className="pop-up-content__buttons forgot-step two-buttons ">
            {width < 1024 && (
              <div onClick={() => setStep(1)} className="pushable back-button">
                <div className="front refreshBtn">
                  <span>Back</span>
                </div>
              </div>
            )}
            <button
              onClick={next}
              className="pushable next-button"
              disabled={isDisabledButton()}
            >
              <span className="shadow"></span>
              <span className="edge "></span>
              {width > 1024 && (
                <span className="front refreshBtn login">Next</span>
              )}
              {width < 1024 && (
                <div className="front refreshBtn">
                  <span>Next</span>
                  <div className="next-button-icon"></div>
                </div>
              )}
            </button>
          </div>
        </>
      )}

      {step === 3 && (
        <>
          {width > 1024 && (
            <div className="signUpStep">
              <img
                src={SignUpStep2}
                style={{ marginTop: '24px', marginBottom: '44px' }}
              />
            </div>
          )}
          {width < 1024 && (
            <div
              className="pop-up-content_title"
              onClick={() => onDidDismiss(false)}
            >
              <span className="pop-up-content_title-step"> Step 2 of 2 </span>
              <img src={closeLogin} />
            </div>
          )}
          <div className="loginPopUpContainer">
            {width > 1024 && (
              <div className="stepThreeTitle">You’re all set</div>
            )}
            {width < 1024 && (
              <div className="stepThreeTitle">
                <span>Password successfully</span>
                <span>modified</span>
              </div>
            )}
            <div className="allSetImg">
              <img src={allSet}></img>
            </div>
            <div
              className="terms-conditions"
              // style={{cursor: 'pointer'  }}
            >
              {isRulesAccepted ? (
                <img
                  onClick={() => setIsRulesAccepted(!isRulesAccepted)}
                  src={rulesAccepted}
                  style={{ marginRight: '2px', cursor: 'pointer' }}
                />
              ) : (
                <div
                  onClick={() => setIsRulesAccepted(!isRulesAccepted)}
                  className="rulesEmpty"
                ></div>
              )}
              <span className="signUpRulesText">
                I accept the {width > 1024 && 'following'}{' '}
                <span
                  onClick={() => actions.setIsTermCondition(true)}
                  className="signUpRulesText-terms"
                >
                  {' '}
                  &nbsp; Terms & Conditions
                </span>
                <br /> and confirm that I am 18+
              </span>
            </div>
          </div>

          {/*<div className="pop-up-content__buttons forgot-step two-buttons">*/}
          <div className="pop-up-content__buttons forgot-step">
            {/*{width < 1024 &&*/}
            {/*  <div onClick={() => setStep(1)} className="pushable back-button">*/}
            {/*    <div className="front refreshBtn">*/}
            {/*      <span>Back</span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*}*/}
            <button
              // onClick={() => {isRulesAccepted &&
              //   onDidDismiss(false)
              //   actions.setTab(1)}
              // }
              onClick={() => {
                if (isRulesAccepted) {
                  onDidDismiss(false)
                  actions.setTab(1)
                } else {
                  toastr.error('', `Please, accept the terms`)
                }
              }}
              className="pushable next-button"
              disabled={isSending}
            >
              <span className="shadow"></span>
              <span className="edge "></span>
              {width > 1024 && (
                <span className="front refreshBtn signUp">Confirm</span>
              )}
              {width < 1024 && (
                <div className="front refreshBtn">
                  <span>Confirm</span>
                </div>
              )}
            </button>
          </div>
        </>
      )}

      {step === 5 && (
        <>
          <div className="signUpNew_wrapper">
            <div
              className="signUpNew_closeButton"
              onClick={() => onDidDismiss(false)}
            ></div>
            <div className="signUpNew">
              <div className="signUpNew_header"></div>
            </div>

            <IonSegment
              onIonChange={(e) => {
                setFgPassTab(e.detail.value)
                console.log(`${e.detail.value} segment selected`)
              }}
              value={fgPassTab}
              className="forgetpasswabox"
            >
              <IonSegmentButton value="wa">
                <IonLabel>Whatsapp</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="sms">
                <IonLabel>SMS</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {fgPassTab === 'wa' ? (
              <>
                <div className="loginPopUpContainer forgot-step">
                  <div className="signUpNew_inputContainer_otp">
                  {(!otpSent && ( <div
                      className={`signUpNew_inputContainer_otp-button`}
                      onClick={() => {
                        // window.location.href = `https://lotus365.authlink.me/?redirectUrl=` + window.location.origin

                        window.open(
                          `https://lotus365.authlink.me/?redirecturl=` +
                            window.location.origin,
                          'Verify with Whats app',
                          'width=420,height=730,left=150,top=200,toolbar=1,status=1,'
                        )

                        // window.open(
                        //   '?waId=123456',
                        //   'Verify with Whats app',
                        //   'width=420,height=730,left=150,top=200,toolbar=1,status=1,'
                        // )
                        
                        setOtpSent(true)

                        const interval = setInterval(() => {
                          const waId = document.getElementById('otp').value
                          if (waId) {
                            setOtp(waId)
                            clearInterval(interval)
                          }
                        }, 1000)
                      }}
                    >
                      Get OTP
                    </div>))}
                  </div>

                  {otpSent && (
                    <>
                      <div div className="signUpNew_inputContainer">
                        <input
                          className="signUpNew_inputContainer-input"
                          formcontrolname="otp"
                          placeholder="OTP"
                          name="otp"
                          type="text"
                          pattern="\d*"
                          id='otp'
                          autocomplete="one-time-code"
                          required
                          readOnly
                          disabled
                          onChange={(e) => validateOtp(e.target.value)}
                        />
                        <div
                          className={`signUpMessageError ${
                            otpMessage ? 'otpMessage' : ''
                          }`}
                        >
                          {otpMessage}
                        </div>
                        {isSendingNew && (
                          <div className="signUpNew_inputContainer-resendOTP">
                            <div
                              style={
                                secondsNew === 0
                                  ? {
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    }
                                  : null
                              }
                              onClick={() =>
                                secondsNew === 0 ? setSecondsNew(59) : null
                              }
                            >
                              <span
                                className="signUpNew_inputContainer-resendOTP"
                                onClick={secondsNew === 0 ? resendOTP : null}
                              >
                                Resend OTP
                              </span>
                              {minutesNew === 0 && secondsNew === 0 ? null : (
                                <span>
                                  {' '}
                                  in {minutesNew}:
                                  {secondsNew < 10
                                    ? `0${secondsNew}`
                                    : secondsNew}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      <div div className="signUpNew_inputContainer lock">
                        <input
                          required
                          ref={ref_input}
                          formcontrolname="122"
                          placeholder="Password"
                          name="122"
                          className={
                            hideFirstPass
                              ? 'signUpNew_inputContainer-input signup-password'
                              : 'signUpNew_inputContainer-input passwordDots'
                          }
                          autoComplete="off"
                          onKeyDown={onKeyDown}
                          onChange={(e) => validatePassword(e.target.value)}
                        />
                        <div
                          className={`${
                            hideFirstPass
                              ? 'signUpNew_icons eyeOff'
                              : 'signUpNew_icons eye'
                          } `}
                          onClick={() => setHideFirstPass(!hideFirstPass)}
                        ></div>
                        
                        <div className="signUpNew_inputContainer_row">
                          <div className="signUpNew_inputContainer_row-error">
                            {passwordMessage}
                          </div>
                        </div>
                      </div>

                      <div
                        div
                        className="signUpNew_inputContainer lock"
                        style={{ marginBottom: '50px' }}
                      >
                        <input
                          formcontrolname="122"
                          placeholder="Confirm Password"
                          name="122"
                          className={
                            hideSecondPass
                              ? 'signUpNew_inputContainer-input signup-password'
                              : 'signUpNew_inputContainer-input passwordDots'
                          }
                          autoComplete="off"
                          onKeyDown={onKeyDown}
                          onChange={(e) =>
                            validatePasswordConfirm(e.target.value)
                          }
                        />
                        <div
                          className={`${
                            hideSecondPass
                              ? 'signUpNew_icons eyeOff'
                              : 'signUpNew_icons eye'
                          } `}
                          onClick={() => setHideSecondPass(!hideSecondPass)}
                        ></div>
                        
                        <div className="signUpNew_inputContainer_row">
                          <div className="signUpNew_inputContainer_row-error">
                            {confirmMessage}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {otpSent && (
                  <div
                    className="pop-up-content__buttons  forgot-step two-buttons"
                    style={{ marginBottom: '20px' }}
                  >
                    {width < 1024 && (
                      <div
                        onClick={() => onDidDismiss(false)}
                        className="pushable back-button"
                      >
                        <div className="front refreshBtn">
                          <span>Back</span>
                        </div>
                      </div>
                    )}
                    <button
                      onClick={nextNewWa}
                      style={
                        isSending && width < 1024
                          ? { background: '#c0bdbd' }
                          : { marginBottom: '10px' }
                      }
                      className="pushable next-button signUpButton"
                      disabled={isDisabledButton()}
                    >
                      <span className="shadow"></span>
                      <span className="edge "></span>
                      {isSending && width > 1024 ? (
                        <>
                          <span
                            style={isSending ? { background: '#c0bdbd' } : {}}
                            className="front refreshBtn login"
                          ></span>
                          <section className="signUpSpinner">
                            <IonSpinner className="spinnerLines" name="lines" />
                          </section>
                        </>
                      ) : width > 1024 ? (
                        <span className="front refreshBtn login">
                          Update password
                        </span>
                      ) : null}
                      {isSending && width < 1024 ? (
                        <>
                          <span className="front refreshBtn"></span>
                          <section className="signUpMobileSpinner">
                            <IonSpinner className="spinnerLines" name="lines" />
                          </section>
                        </>
                      ) : (
                        width < 1024 && (
                          <div className="front refreshBtn">
                            <span>Done</span>
                            <div className="next-button-icon"></div>
                          </div>
                        )
                      )}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="loginPopUpContainer forgot-step">
                  <div div className="signUpNew_inputContainer">
                    <input
                      className="signUpNew_inputContainer-input"
                      onFocus={onfocusInput}
                      onBlur={onBlurInput}
                      formcontrolname="username"
                      placeholder="10 Digit Phone Number"
                      name="username"
                      type="tel"
                      autoComplete="username"
                      onChange={(e) => validateMobile(e.target.value)}
                    />
                    <div className="signUpNew_inputContainer_row">
                      <div className="signUpNew_inputContainer_row-error">
                        {message}
                      </div>
                      <span
                        className={`signUpNew_inputContainer_row-counter ${
                          mobileNumCounter === 10 && 'counterDone'
                        }`}
                      >
                        {mobileNumCounter}/10
                      </span>
                    </div>
                    <div className="signUpNew_inputContainer_otp">
                      <div
                        className={`signUpNew_inputContainer_otp-button ${
                          otpSent ? 'otpButtonDisabled' : ''
                        }`}
                        onClick={() =>
                          !otpSent ? mobileValidationNew() : null
                        }
                      >
                        Get OTP
                      </div>
                    </div>
                  </div>

                  {otpSent && (
                    <>
                      <div div className="signUpNew_inputContainer">
                        <input
                          className="signUpNew_inputContainer-input"
                          formcontrolname="otp"
                          placeholder="OTP"
                          name="otp"
                          type="number"
                          pattern="\d*"
                          autocomplete="one-time-code"
                          required
                          maxLength={4}
                          onChange={(e) => validateOtp(e.target.value)}
                        />
                        {/* <OtpInput
                          value={otp}
                          onChange={validateOtp}
                          numInputs={4}
                          renderSeparator={<span> </span>}
                          inputStyle="otp-input"
                          containerStyle="containerStyle"
                          renderInput={(props) => <input {...props} />}
                        /> */}
                        <div
                          className={`signUpMessageError ${
                            otpMessage ? 'otpMessage' : ''
                          }`}
                        >
                          {otpMessage}
                        </div>
                        {isSendingNew && (
                          <div className="signUpNew_inputContainer-resendOTP">
                            <div
                              style={
                                secondsNew === 0
                                  ? {
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    }
                                  : null
                              }
                              onClick={() =>
                                secondsNew === 0 ? setSecondsNew(59) : null
                              }
                            >
                              <span
                                className="signUpNew_inputContainer-resendOTP"
                                onClick={secondsNew === 0 ? resendOTP : null}
                              >
                                Resend OTP
                              </span>
                              {minutesNew === 0 && secondsNew === 0 ? null : (
                                <span>
                                  {' '}
                                  in {minutesNew}:
                                  {secondsNew < 10
                                    ? `0${secondsNew}`
                                    : secondsNew}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      <div div className="signUpNew_inputContainer lock">
                        <input
                          required
                          ref={ref_input}
                          formcontrolname="122"
                          placeholder="Password"
                          name="122"
                          className={
                            hideFirstPass
                              ? 'signUpNew_inputContainer-input signup-password'
                              : 'signUpNew_inputContainer-input passwordDots'
                          }
                          autoComplete="off"
                          onKeyDown={onKeyDown}
                          onChange={(e) => validatePassword(e.target.value)}
                        />
                        <div
                          className={`${
                            hideFirstPass
                              ? 'signUpNew_icons eyeOff'
                              : 'signUpNew_icons eye'
                          } `}
                          onClick={() => setHideFirstPass(!hideFirstPass)}
                        ></div>
                        {/* <img
                className="icons"
                src={hideFirstPass ? eyeOff : eye}
                onClick={() => setHideFirstPass(!hideFirstPass)}
              /> */}
                        <div className="signUpNew_inputContainer_row">
                          <div className="signUpNew_inputContainer_row-error">
                            {passwordMessage}
                          </div>
                        </div>
                      </div>

                      <div
                        div
                        className="signUpNew_inputContainer lock"
                        style={{ marginBottom: '50px' }}
                      >
                        <input
                          formcontrolname="122"
                          placeholder="Confirm Password"
                          name="122"
                          className={
                            hideSecondPass
                              ? 'signUpNew_inputContainer-input signup-password'
                              : 'signUpNew_inputContainer-input passwordDots'
                          }
                          autoComplete="off"
                          onKeyDown={onKeyDown}
                          onChange={(e) =>
                            validatePasswordConfirm(e.target.value)
                          }
                        />
                        <div
                          className={`${
                            hideSecondPass
                              ? 'signUpNew_icons eyeOff'
                              : 'signUpNew_icons eye'
                          } `}
                          onClick={() => setHideSecondPass(!hideSecondPass)}
                        ></div>
                        {/* <img
                className="icons"
                src={hideSecondPass ? eyeOff : eye}
                onClick={() => setHideSecondPass(!hideSecondPass)}
              /> */}
                        <div className="signUpNew_inputContainer_row">
                          <div className="signUpNew_inputContainer_row-error">
                            {confirmMessage}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {otpSent && (
                  <div
                    className="pop-up-content__buttons  forgot-step two-buttons"
                    style={{ marginBottom: '20px' }}
                  >
                    {width < 1024 && (
                      <div
                        onClick={() => onDidDismiss(false)}
                        className="pushable back-button"
                      >
                        <div className="front refreshBtn">
                          <span>Back</span>
                        </div>
                      </div>
                    )}
                    <button
                      onClick={nextNew}
                      style={
                        isSending && width < 1024
                          ? { background: '#c0bdbd' }
                          : { marginBottom: '10px' }
                      }
                      className="pushable next-button signUpButton"
                      disabled={isDisabledButton()}
                    >
                      <span className="shadow"></span>
                      <span className="edge "></span>
                      {isSending && width > 1024 ? (
                        <>
                          <span
                            style={isSending ? { background: '#c0bdbd' } : {}}
                            className="front refreshBtn login"
                          ></span>
                          <section className="signUpSpinner">
                            <IonSpinner className="spinnerLines" name="lines" />
                          </section>
                        </>
                      ) : width > 1024 ? (
                        <span className="front refreshBtn login">
                          Update password
                        </span>
                      ) : null}
                      {isSending && width < 1024 ? (
                        <>
                          <span className="front refreshBtn"></span>
                          <section className="signUpMobileSpinner">
                            <IonSpinner className="spinnerLines" name="lines" />
                          </section>
                        </>
                      ) : (
                        width < 1024 && (
                          <div className="front refreshBtn">
                            <span>Done</span>
                            <div className="next-button-icon"></div>
                          </div>
                        )
                      )}
                    </button>
                  </div>
                )}
              </>
            )}

            {/* <div className="signUpNew_info" >
            <span className="signUpNew_info-text">Already have account? </span>&nbsp;
            <span onClick={() => actions.setTab(1)} className="signUpNew_info-link">Log In</span>
          </div> */}

            {/* <div className='signUpNew-separator'>
            <div className='signUpNew-separator-rightLine'></div>
            <div className='signUpNew-separator-or'>OR</div>
            <div className='signUpNew-separator-leftLine'></div>
          </div>

          <div className='signUpNew_whattsUp'>
            <div className='signUpNew_whattsUp_title'>Get your ready-made ID from whatsapp</div>
            <div className='signUpNew_whattsUp_button' onClick={() => window.open(`https://wa.me/${CONFIG.mobiNumber}`)}>
              <a href={`https://wa.me/8888888888`}
                target="_blank"
                className="landing-footer-social-button whatsapp">
                <span>8888888888</span>
              </a>
              <div className='signUpNew_whattsUp_button-icon'></div>
              <div className='signUpNew_whattsUp_button-number'>{CONFIG.mobiNumber}</div>
            </div>
          </div> */}
          </div>
        </>
      )}
    </>
  )
}

export default connectLoginPopUp()(ForgotPasswordTab)
